export const regex = /^(\d+)\.(\d+)\.(\d+)/;
export const compareSemver = (versionA: string, versionB: string) => {
  const partsA = regex.exec(versionA);
  const partsB = regex.exec(versionB);
  if (!partsA || !partsB) return NaN;

  for (let i = 1; i <= 3; i += 1) {
    const a = Number(partsA[i]);
    const b = Number(partsB[i]);
    if (a > b) return 1;
    if (a < b) return -1;
  }
  return 0;
};

export const lessThan = (versionA: string, versionB: string) =>
  compareSemver(versionA, versionB) < 0;
export const greaterThan = (versionA: string, versionB: string) =>
  compareSemver(versionA, versionB) > 0;
export const greaterOrEqual = (versionA: string, versionB: string) =>
  compareSemver(versionA, versionB) > -1;
export const lessOrEqual = (versionA: string, versionB: string) =>
  compareSemver(versionA, versionB) < 1;
