import type { Middleware } from 'redux';

let logrocketMiddleware: Middleware;

export const initMiddleware = (value: Middleware) => {
  logrocketMiddleware = value;
};

export const middleware: Middleware = (api) => (next) => (action) => {
  if (logrocketMiddleware) {
    return logrocketMiddleware(api)(next)(action);
  }
  return next(action);
};
