import type { Call } from '@twilio/voice-sdk';

interface StateValue {
  timeout?: number;
}

// we can use this for timing out multiple calls independent of each other
const state = new WeakMap<Call, StateValue>();

export const attach = (call: Call, timeoutMs: number, callback: (call: Call) => void) => {
  if (state.has(call)) detach(call);
  state.set(call, {});
  // handle dropped calls without any specific event by checking for the 'sample' event
  // https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#sample-event
  call.on('sample', () => {
    const callState = state.get(call);
    if (!callState) return;

    if (callState.timeout) window.clearTimeout(callState.timeout); // clear old timeout

    // schedule if we receive nothing
    callState.timeout = window.setTimeout(() => {
      if (state.has(call)) {
        state.delete(call);
        callback(call);
      }
    }, timeoutMs);
  });
};

export const detach = (call: Call) => {
  const callState = state.get(call);
  if (callState) state.delete(call);
  if (callState?.timeout) window.clearTimeout(callState.timeout);
};