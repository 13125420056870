import type { Store } from 'redux';
import type { ReduxState } from '@/redux/app/state';
import * as semver from '@/lib/semver';

const version = '2';

export interface ReactNativeEventListeners {
  'react-native:unfreeze'?: () => void,
}

enum ReactNativeMethod {
  INIT = 'init',
  LOGOUT = 'logout',
  DIAL = 'dial',
  TRIGGER_VISIT = 'turbolinks-before_visit',
  NAVIGATE = 'turbolinks-load',
  NAVIGATE_REACT = 'react-navigate',
  SAVE_IMAGE = 'save_image',
  STAGE_SWITCH = 'switch-stage',
  UPLOAD_DOCUMENT = 'upload-document',
  INIT_TWILIO = 'init-twilio',
}

interface ReactNativePayload {
  version: string;
  method: ReactNativeMethod;
  [key: string]: any;
}

type ReduxEvent = CustomEvent<{
  reduxEventType: string;
  payload: Record<string, any>;
}>;
type WebAppApiEvent = CustomEvent<{
  eventType: string;
  payload: Record<string, any>;
}>;

type Listeners = Array<[key: string, listener: (event: any) => void]>;

const sendPayload = (data: ReactNativePayload) =>
  window.ReactNativeWebView?.postMessage(JSON.stringify(data));

type NavigatorApi = Record<'navigate' | 'open' | 'back', (...args: any) => void>;

const twilioListenerKeys = ['init-twilio', 'call-disconnect'] as const;
type TwilioListeners = Record<typeof twilioListenerKeys[number], () => void>;

export const ReactNative = {
  exists() {
    return typeof window.ReactNativeWebView !== 'undefined';
  },

  get platform() {
    if (!this.exists()) return undefined;
    return navigator.userAgent.substring(13).split('/')[0] as 'Android' | 'iPhone' | 'iPad';
  },

  get platformVersion() {
    if (!this.exists()) return undefined;

    const plusIx = navigator.userAgent.indexOf('+');
    const osIx = navigator.userAgent.substring(plusIx).indexOf(' ');
    if (osIx < 0) { // old user agent string
      return null;
    }
    return navigator.userAgent.substring(plusIx + osIx).split('/').pop();
  },

  get appVersion() {
    if (!this.exists()) return undefined;
    const startIx = navigator.userAgent.indexOf('/') + 1;
    const endIx = navigator.userAgent.indexOf('(');
    return navigator.userAgent.substring(startIx, endIx);
  },

  attach(
    store: Store<ReduxState>,
    navigator: NavigatorApi,
    eventListeners: ReactNativeEventListeners = {},
  ) {
    let listeners: Listeners;
    if (this.exists()) {
      // See https://github.com/avochato/avochato_mobile/blob/d87d4c6404b858c9bbe5f27204a0ef12244f0cf4/src/utils/web-app-api.ts#L34
      listeners = Object.entries(eventListeners);
      listeners.push(['remote-redux', (event: ReduxEvent) => { // legacy; for app versions < 2.16
        if (event.detail.reduxEventType === 'dispatch') {
          store.dispatch(event.detail.payload.action);
        }
      }]);
      listeners.push(['web-app-api', (event: WebAppApiEvent) => {
        switch (event.detail.eventType) {
          case 'redux:dispatch': {
            const { action } = event.detail.payload;
            store.dispatch(action);
            break;
          }
          case 'navigator:action': {
            const { action, args } = event.detail.payload;
            navigator[action]?.(...args);
            break;
          }
        }
      }]);

      listeners.forEach(([key, value]) => window.addEventListener(key, value));

      let history: any;
      store.subscribe(() => {
        const state = store.getState();
        if (state.navigation.history !== history) {
          history = state.navigation.history;
          sendPayload({
            version,
            method: ReactNativeMethod.NAVIGATE_REACT,
            url: window.location.toString(),
          });
        }
      });

      sendPayload({
        version,
        method: ReactNativeMethod.INIT,
        react: true,
      });
    }

    return () => this.detach(listeners);
  },

  attachTwilio(listeners: TwilioListeners) {
    if (this._twilioListener) window.removeEventListener('web-app-api', this._twilioListener);
    this._twilioListener = (event: WebAppApiEvent) => {
      twilioListenerKeys.forEach((key) => {
        if (listeners[key] && event.detail.eventType === `twilio:${key}`) {
          listeners[key]();
        }
      });
    };
    window.addEventListener('web-app-api', this._twilioListener);
    return this._twilioListener;
  },

  detach(eventListeners: Listeners) {
    if (!this.exists()) return;

    sendPayload({
      version,
      method: ReactNativeMethod.INIT,
      react: false,
    });
    eventListeners.forEach(([key, value]) => window.removeEventListener(key, value));
  },

  saveImage(imageUrl: string) {
    if (!this.exists()) return;
    sendPayload({
      version,
      method: ReactNativeMethod.SAVE_IMAGE,
      image_url: imageUrl,
    });
  },

  uploadDocument(mimeType = '*/*') {
    if (!this.exists()) return undefined;

    return new Promise<File>((resolve) => {
      const listener = (event: WebAppApiEvent) => {
        const { eventType, payload } = event.detail;
        if (eventType !== 'send:file') return;
        window.removeEventListener('web-app-api', listener);
        const { name, mimeType: type, contents } =
          payload as { name: string, mimeType: string, contents: string };
        resolve(new File([contents], name, { type }));
      };
      // See https://github.com/avochato/avochato_mobile/blob/d87d4c6404b858c9bbe5f27204a0ef12244f0cf4/src/utils/web-app-api.ts#L34
      window.addEventListener('web-app-api', listener);
      sendPayload({
        version,
        method: ReactNativeMethod.UPLOAD_DOCUMENT,
        mime_type: mimeType,
      });
    });
  },

  dial(
    twilioToken: string,
    rememberToken: string,
    twilioPayload: Record<string, string>,
    phone: string,
    name: string,
  ): boolean {
    const self = this as typeof ReactNative;
    if (!self.exists()) return false;
    // check if user is using Android app version >= 2.3.0
    if (self.platform === 'Android' && !semver.greaterOrEqual(self.appVersion, '2.3.0')) return false;
    if (!semver.greaterOrEqual(self.appVersion, '2.30.0')) {
      sendPayload({
        ...twilioPayload,
        version,
        method: ReactNativeMethod.DIAL,
        to: phone,
        access_token: twilioToken,
        remember_token: rememberToken,
      });
      return true;
    }
    sendPayload({
      version,
      method: ReactNativeMethod.DIAL,
      to: phone,
      name,
    });
    return true;
  },

  initTwilio(twilioToken: string, twilioPayload: Record<string, string>) {
    const self = this as typeof ReactNative;
    if (!self.exists()) return false;
    // check if user is using app version >= 2.30.0
    if (!semver.greaterOrEqual(self.appVersion, '2.30.0')) return false;
    sendPayload({
      version,
      method: ReactNativeMethod.INIT_TWILIO,
      twilioToken,
      twilioPayload,
    });
    return true;
  },
};
