export class AppNotification {
  max: number;
  notificationsContainer: HTMLElement;
  showMoreNotifs: HTMLElement;
  noNotifs: HTMLElement;
  notifications: HTMLElement;
  markAll: HTMLElement;
  count: number;

  constructor() {
    this.max = 10;
    this.notificationsContainer = document.getElementById('notification-containers');
    this.showMoreNotifs = document.getElementById('show-more-notifs');
    this.noNotifs = document.getElementById('no-notifs');
    this.notifications = document.getElementById('notifications');
    this.markAll = document.getElementById('mark-all');
    this.count = parseInt(this.notifications.dataset.count);

    this.initialize();
  }

  initialize() {
    let collection = this.notificationsContainer.children
    for (let i = 0; i < collection.length; i++) {
      this.addListenerToNotification(collection[i] as HTMLElement);
    }
    this.addListenerToMarkAll();
    this.addListenerToShowMore();
  }

  markAsRead(notif: Element) {
    const circle = notif.getElementsByClassName('circle')?.[0];
    circle.classList.add('hide');
    this.incrementCount(-1);
  }

  incrementCount(amount: number) {
    const notifRevealerCountContainer = document.getElementById('notif-revealer-count-container');
    const notifRevealerCount = document.getElementById('notif-revealer-count');
    const notifRevealerCountPlus = document.getElementById('notif-revealer-count-plus');
    const countContainer = document.getElementById('count-container');
    const notifCount = document.getElementById('notif-count');
    const notifCountPlus = document.getElementById('notif-count-plus');

    this.count += amount;

    if (this.count <= 0) {
      notifRevealerCountContainer?.classList.add('hide');
      notifCount?.classList.add('hide');
      countContainer?.classList.add('hide');
    }
    if (this.count == 1) {
      notifRevealerCountContainer?.classList.remove('hide');
      notifCount?.classList.remove('hide');
      countContainer?.classList.remove('hide');
    }
    if (this.count >= this.max) {
      notifRevealerCountPlus?.classList.remove('hide');
      notifCountPlus?.classList.remove('hide');
    } else {
      notifRevealerCountPlus?.classList.add('hide');
      notifCountPlus?.classList.add('hide');
    }
    const renderedCount = String(this.count >= this.max ? this.max : this.count);

    if (notifRevealerCount) notifRevealerCount.innerHTML = renderedCount;
    if (notifCount) notifCount.innerHTML = renderedCount;
  }

  addListenerToNotification(notif: HTMLElement) {
    const circle = notif.getElementsByClassName('circle')?.[0];
    if (!circle) return;

    const callback = () => {
      const oid = notif.dataset.oid;
      const isRead = document.getElementById(`app-notification-${oid}`)?.querySelector('.circle')?.classList?.contains('hide');

      if (isRead) return;

      $.ajax({
        method: 'PUT',
        url: `/users/${this.notifications.dataset.user_id}/app_notifications/${oid}/mark_as_read`,
        success: () => this.markAsRead(notif)
      })
    }

    notif.addEventListener('click', callback);
    document.addEventListener('beforeunload', () => notif.removeEventListener('click', callback));
  }

  addListenerToMarkAll() {
    const callback = () => {
      $.ajax({
        method: 'POST',
        url: `/users/${this.notifications.dataset.user_id}/app_notifications/mark_all_as_read`,
        success: () => {
          let collection = this.notificationsContainer.children;
          for (let i = 0; i < collection.length; i++) {
            let notif = collection[i];
            const circle = notif.getElementsByClassName('circle')?.[0];
            if (!circle) continue;
            this.markAsRead(notif);
          }
          this.incrementCount(-this.count);
        }
      })
    }

    this.markAll.addEventListener('click', callback);
    document.addEventListener('beforeunload', () => this.markAll.removeEventListener('click', callback));
  }

  addListenerToShowMore() {
    const callback = () => {
      const last_key = this.notifications.dataset.last_key;
      const last = this.notificationsContainer.children.length-1;
      const baseUrl = location.href.split('#')[0];

      location.href = `${baseUrl}#${this.notificationsContainer.children[last].id}`;

      $.ajax({
        method: 'GET',
        url: `/users/${this.notifications.dataset.user_id}/app_notifications`,
        data: { last_key },
      })
    }

    this.showMoreNotifs.addEventListener('click', callback);
    document.addEventListener('beforeunload', () => this.showMoreNotifs.removeEventListener('click', callback));
  }

  handleReceiveNotification(data) {
    // append new notification
    this.notificationsContainer.insertAdjacentHTML('afterbegin', data.html);
    // add listener to newest notif
    this.addListenerToNotification(this.notificationsContainer.children[0] as HTMLElement);
    // update counts
    this.incrementCount(1);

    this.noNotifs?.classList?.add('hide');
    this.showMoreNotifs?.classList?.remove('hide');
    this.markAll?.classList?.remove('hide');
  }
}
