import type LogRocketModule from 'logrocket';
import type { UserInfoProps } from '@/components/subcomponents/user/user-info';
import { initMiddleware } from '@/redux/app/middleware/logrocket.middleware';

export interface LogRocketConfig {
  enabled: boolean;
  appId: string;
}

let LogRocket: typeof LogRocketModule;

export const init = async (config: LogRocketConfig, userInfo: UserInfoProps) => {
  if (process.env.NODE_ENV === 'development') return;
  if (!LogRocket && config.enabled) {
    ({ default: LogRocket } = await import('logrocket'));
    LogRocket.init(config.appId);
    initMiddleware(LogRocket.reduxMiddleware());
    const { userId, name, email } = userInfo;
    LogRocket.identify(userId, { name, email });

    // Make track also log events to console - to see during session replay
    const { track } = LogRocket;
    LogRocket.track = (...args) => {
      track.apply(LogRocket, args);
      LogRocket.log(...args);
    };
  }
};

type LogMethod = (message: string, extraInfo?: object) => void;
// changes an object argument type to a function returning an object type, so that we can catch errors
type Callbackify<Type extends any> = Type extends object ? () => Type : Type;
type TransformArgs<Args extends [...any[]]> = {
  [Index in keyof Args]: Callbackify<Args[Index]>;
};

const safeMethod = <M extends LogMethod>(methodName: string) => (...args: TransformArgs<Parameters<M>>) => {
  if (process.env.NODE_ENV !== 'development' && !LogRocket) return;

  const [message, getExtraInfo] = args;
  let extraInfo: any;
  try {
    extraInfo = getExtraInfo?.();
  } catch (err) {
    if (process.env.NODE_ENV === 'development') throw err;
    LogRocket.log(`LogRocket.${methodName} failed: ${message}`);
    LogRocket.error(err);
  }
  LogRocket[methodName](message, extraInfo);
};

export const log = safeMethod<LogMethod>('log');
export const track = safeMethod<typeof LogRocket.track>('track');

export const error: typeof LogRocket.error = (...args) => LogRocket?.error(...args);