import { Device, Call, TwilioError } from '@twilio/voice-sdk';

import * as LogRocket from '@/api/app/logrocket';
import * as CallTimeout from '@/lib/call-timeout';
import { avo } from './avo-client';
import { ReactNative } from './react-native';

declare global {
  interface Window {
    incomingCallPrompt: IncomingCallPrompt;
    dialManager: DialManager;
  }
}

interface IncomingCallPrompt {
  show(conn: {
    from: string;
    onAccept(): void;
    onIgnore(): void;
  }): void;
  close(): void;
}

interface Callee {
  name: string;
  phone: string;
}

interface DialManager {
  open(call: Call, callee: Callee): void;
  updateCallee(callee: Callee): void;
  close(): void;
  hangup(): void;
}

export const config = {
  initBackoff: 100,
  callTimeoutMs: 5000,
  tokenRefreshMs: 3600 * 1000, // make twilio to refresh the token one hour before expiration
  forceUpdateTokenAfterMs: 23.5 * 3600 * 1000, // when refresh it manually if twilio fails to do it
};

export enum Status {
  UNINITIALIZED = 'uninitialized',
  READY = 'ready',
  CALL_IN_PROGRESS = 'call_in_progress',
}

export const status = {
  get(): Status {
    return this.value;
  },
  update(value: Status) {
    this.value = value;
    this.listeners?.forEach((listener) => listener(value));
  },
  addListener(listener: (value: Status) => void) {
    this.listeners ??= [];
    this.listeners.push(listener);
  },
  removeListener(listener: (value: Status) => void) {
    this.listeners = this.listeners?.filter((v) => v !== listener);
  },
};
status.update(Status.UNINITIALIZED);

const cleanupAfterCall = (call: Call) => {
  window.dialManager?.close();
  window.incomingCallPrompt?.close();
  CallTimeout.detach(call);
  status.update(Status.READY);
};

const prepareCall = (call: Call) => {
  call.on('cancel', () => {
    LogRocket.track('Call Canceled');
    cleanupAfterCall(call);
  });
  call.on('disconnect', () => {
    LogRocket.track('Call Disconnected');
    cleanupAfterCall(call);
  });
  call.on('error', (error: Error) => {
    console.error(error);
    cleanupAfterCall(call);
  });
  call.on('reject', () => {
    LogRocket.track('Call Rejected');
    cleanupAfterCall(call);
  });
  CallTimeout.attach(call, config.callTimeoutMs, () => {
    console.warn('Call "sample" event timed out, hanging up and cleaning up the call.');
    window.dialManager?.hangup();
    cleanupAfterCall(call);
  });
};

let twilioPayload: Record<string, string>;

const fetchToken = async (subdomain: string, retries = 2) => {
  const result = await avo.ajax<{ twilio_token: string, twilio_payload: typeof twilioPayload }>({
    method: 'POST',
    route: '/v1/calls',
    payload: { subdomain },
  }, { retries });
  twilioPayload = result.data.twilio_payload;
  return result.data.twilio_token;
};

export const initTwilio = (subdomain: string, debug = false, backoff = config.initBackoff) => {
  fetchToken(subdomain, 0)
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    .then((token) => registerDevice(subdomain, token, debug))
    .catch((err) => {
      console.error(err);
      setTimeout(() => initTwilio(subdomain, debug, backoff * 2), backoff);
    });
};

let device: Device;
export const getDevice = () => device;

export const registerDevice = (subdomain: string, token: string, debug = false) => new Promise((resolve, reject) => {
  device?.destroy(); // need to explicitly destroy previous device to prevent memory leak

  if (!token) return;

  if (ReactNative.exists()) {
    ReactNative.attachTwilio({
      'init-twilio': () => {
        LogRocket.track('Twilio Device Registered');
        status.update(Status.READY);
        resolve(null);
      },
      'call-disconnect': () => {
        LogRocket.track('Call Disconnected');
        status.update(Status.READY);
      },
    });

    const rememberToken = window.sharedApi.getRememberToken();
    const sessionId = window.sharedApi.getSessionId();
    const rnPayload = { ...twilioPayload, remember_token: rememberToken, session_id: sessionId };
    if (ReactNative.initTwilio(token, rnPayload)) {
      LogRocket.log('Using React Native Twilio library');
      return;
    } else {
      LogRocket.log('Old app version, using Twilio in the webview');
    }
  }

  device = new Device(token, {
    appVersion: 'v2.0',
    closeProtection: 'Leaving this page will end your current call. Continue?',
    maxAverageBitrate: 64000,
    tokenRefreshMs: config.tokenRefreshMs,
    ...(debug && { logLevel: 'debug' }),
  });
  const initState = {
    deviceActive: false, // assume device is active after assumeDeviceActiveAfterMs delay passes with no error
    deviceError: null,
  };

  // Twilio's TokenWillExpireEvent can be unreliable, so we set a timeout to ensure token gets updated
  let tokenTimeout: number;
  const updateDeviceToken = async () => {
    clearTimeout(tokenTimeout);
    device.updateToken(await fetchToken(subdomain));
    tokenTimeout = window.setTimeout(updateDeviceToken, config.forceUpdateTokenAfterMs);
  };
  tokenTimeout = window.setTimeout(updateDeviceToken, config.forceUpdateTokenAfterMs);
  device.on(Device.EventName.TokenWillExpire, updateDeviceToken);

  device.on(Device.EventName.Unregistered, () => {
    LogRocket.track('Twilio Device Unregistered');
    // This can happen when user's machine goes to sleep or Internet connection is lost.
    // Twilio attempts to re-register it again when the connection is back.
    // Trying to do it manually triggers a race condition in Twilio logic and causes the tab to run out of memory.
    // https://github.com/twilio/twilio-voice.js/issues/110#issuecomment-1920135293
    if (initState.deviceActive) {
      status.update(Status.UNINITIALIZED);
    } else if (initState.deviceError) {
      LogRocket.error(initState.deviceError);
      reject(initState.deviceError);
    }
  });
  device.on(Device.EventName.Registered, () => {
    LogRocket.track('Twilio Device Registered', () => ({ wasActive: initState.deviceActive }));
    status.update(Status.READY);
    if (!initState.deviceActive) {
      initState.deviceActive = true;
      resolve(device);
    }
  });
  device.on(Device.EventName.Incoming, (call: Call) => {
    LogRocket.track('Incoming Call Received', () => ({
      status: status.get(),
      ...[...call.customParameters.entries()].reduce(
        (acc, [key, value]) => Object.assign(acc, { [`Parameter[${key}]`]: value }),
        {},
      ),
    }));
    if (status.get() === Status.CALL_IN_PROGRESS) return;
    status.update(Status.CALL_IN_PROGRESS);

    const customNumber = call.customParameters.get('from_number');
    const displayName = call.customParameters.get('display_name');
    const phone = customNumber || call.parameters.From;

    let from: string;
    if (displayName && displayName !== phone) {
      from = displayName;
    } else {
      from = phone;
      if (call.callerInfo.isVerified) {
        from += ' ✅';
      }
    }

    let name = displayName;
    if (displayName !== phone) name = displayName;
    name ||= call.parameters.CallerId;
    name ||= 'Incoming Call';

    prepareCall(call);
    window.incomingCallPrompt.show({
      from,
      onAccept: () => {
        LogRocket.track('Incoming Call Accepted');
        window.dialManager.open(call, { name, phone });
        call.accept();
      },
      onIgnore: () => {
        LogRocket.track('Incoming Call Ignored');
        call.ignore();
      },
    });
  });

  const handleRegisterError = (error: TwilioError.TwilioError) => {
    if (error.code === 31005 || error.code === 31009) {
      // This happens if we lose Internet connection when trying to register device
      // In this case, don't throw error and just wait for Twilio to reconnect automatically
      console.error(error);
    } else {
      initState.deviceError = error;
    }
  };
  device.on(Device.EventName.Error, async (twilioError: TwilioError.TwilioError, call: Call) => {
    console.error(twilioError);
    if (call) cleanupAfterCall(call);
    else if (!initState.deviceActive) handleRegisterError(twilioError);
  });

  device.register().catch(handleRegisterError);
});

export const dial = async (phone: string, name?: string) => {
  LogRocket.track('Dialing Number', () => ({ phone, name }));
  if (status.get() === Status.UNINITIALIZED) {
    console.warn('Cannot place a call before Twilio is initialized');
    return;
  }
  const rememberToken = window.sharedApi.getRememberToken();
  const sessionId = window.sharedApi.getSessionId();
  device?.disconnectAll();
  status.update(Status.CALL_IN_PROGRESS);

  const usingNativeCalling = ReactNative.dial(device?.token, rememberToken, twilioPayload, phone, name);
  if (usingNativeCalling) {
    LogRocket.track('Calling via React Native');
    return;
  }

  const call = await device.connect({
    params: {
      ...twilioPayload,
      CallTo: phone,
      remember_token: rememberToken,
      session_id: sessionId,
    },
  });
  prepareCall(call);
  window.dialManager.open(call, { phone, name });
};
