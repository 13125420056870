import type { XOR } from 'ts-xor';
import type JQuery from 'jquery';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import transform from 'lodash/transform';
import isObject from 'lodash/isObject';

export interface AvoClientConfig {
  enrichResponses?: boolean;
  defaultLimit?: number;
  baseUrl?: string;
}

export interface RequestDefinition {
  route: string;
  params?: Record<string, string>;
  payload?: any;
  method?: 'GET'|'POST'|'PUT'|'PATCH'|'DELETE';
  renderFlash?: boolean;
  signal?: AbortSignal;
}
export interface RequestConfig {
  retries?: number;
}
export interface EndpointRequestParams {
  subdomain: string;
  after?: string;
  limit?: number;
  signal?: AbortSignal;
}
export interface FlashMessage {
  type: 'notice'|'error'|'success';
  message: string;
}
export interface EndpointResponse {
  data: any[];
  lastKey?: string;
  totalCount?: number;
  flash?: FlashMessage;
}

export type ExtraRequestParams<Union extends string> = { [param in Union]: string };

export type BulkActionObjectParam =
  XOR<{ ids: string[] },
  XOR<{ uuids: string[] },
  { filters: Record<string, string> }>>;

interface BulkActionAssignParams {
  tickets: BulkActionObjectParam;
  userId: string;
}
interface BulkActionUnassignParams {
  tickets: BulkActionObjectParam;
}
interface BulkActionTagParams {
  tickets: BulkActionObjectParam;
  tagNames: string[];
  accountId: string;
}
interface BulkActionUntagParams extends BulkActionTagParams {}
interface BulkActionChangeStatusParams {
  tickets: BulkActionObjectParam;
  accountId: string;
  status: string;
}
interface BulkActionAddToBroadcastParams {
  tickets: BulkActionObjectParam;
  accountId: string;
  broadcastId: string;
}
interface BulkActionAddressParams {
  tickets: BulkActionObjectParam;
}
export type BulkAction =
  XOR<{ assign: BulkActionAssignParams },
  XOR<{ unassign: BulkActionUnassignParams },
  XOR<{ tag: BulkActionTagParams },
  XOR<{ untag: BulkActionUntagParams },
  XOR<{ changeStatus: BulkActionChangeStatusParams },
  XOR<{ address: BulkActionAddressParams },
  { addToBroadcast: BulkActionAddToBroadcastParams }>>>>>>;

function toCase(obj, fn: typeof camelCase|typeof snakeCase) {
  return transform(obj, (acc, value, key) => {
    acc[typeof key === 'string' ? fn(key) : key] = isObject(value) ? toCase(value, fn) : value;
  });
}

export class AvoClient {
  whitelist = [400, 403, 404];

  constructor(public config: AvoClientConfig = {}) {
    this.config.defaultLimit ??= 50;

    this.init();
  }

  private init() { // eslint-disable-line class-methods-use-this
    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
    });
  }

  ajax<D = any, R = {}>(
    { route, params, payload, method = 'GET', renderFlash, signal }: RequestDefinition,
    { retries = 2 }: RequestConfig = {},
  ): Promise<{ data: D, flash?: FlashMessage }&(R extends never ? {} : R)> {
    return new Promise((resolve, reject) => {
      const data = method === 'GET' ? params : payload;
      let attempt = 0;
      let request: JQuery.jqXHR;
      let dispatchRequest = () => {
        request = $.ajax({
          url: `${this.config.baseUrl ?? ''}${route}`,
          method,
          data: { enrich: this.config.enrichResponses, ...data },
          success: (res, status, xhr) => {
            const flashType = xhr.getResponseHeader('X-Flash-Type');
            if (!flashType) resolve(res);
            else {
              const flashMessage = xhr.getResponseHeader('X-Flash-Message');
              if (renderFlash) window.renderFlash(flashMessage, flashType);
              resolve({ ...res, flash: { type: flashType, message: flashMessage } });
            }
          },
          error: (xhr: JQuery.jqXHR<any>&{ flash?: FlashMessage }) => {
            const flashType = xhr.getResponseHeader('X-Flash-Type');
            if (flashType === 'error') {
              const flashMessage = xhr.getResponseHeader('X-Flash-Message');
              xhr.flash = { type: flashType, message: flashMessage };
              if (renderFlash) window.renderFlash(flashMessage, flashType);
            }

            attempt += 1;

            // don't retry on a whitelisted event
            if (attempt <= retries && this.whitelist.indexOf(xhr.status) < 0) {
              setTimeout(() => dispatchRequest?.(), 2 ** attempt * 1000);
            } else {
              reject(xhr);
            }
          },
        });
      };
      signal?.addEventListener('abort', () => {
        dispatchRequest = null;
        request?.abort();
        reject({ message: 'aborted' });
      });
      dispatchRequest?.();
    });
  }

  async tickets({ limit, userId, signal, ...rest }: EndpointRequestParams&(
    Partial<ExtraRequestParams<
      'query'|'status'|'userId'|'channel'|'from'|'to'|'order'|'unaddressed'|'unanswered'|'archived'
    >>
  )): Promise<EndpointResponse> {
    const request = {
      route: '/v1/tickets',
      params: {
        limit: limit?.toString?.(),
        user_id: userId,
        ...rest,
      },
      signal,
    };

    const { data: { tickets, last_key: lastKey, total_count: totalCount }, flash } =
      await this.ajax<{ tickets: any[], last_key: string, total_count: number }>(request);
    const response: EndpointResponse = { data: tickets, lastKey, totalCount, flash };

    return response;
  }

  async broadcasts({ limit, signal, ...rest }: EndpointRequestParams&(
    Partial<ExtraRequestParams<
      'query'|'from'|'to'|'order'
    >>
  )): Promise<EndpointResponse> {
    const request = {
      route: '/v1/broadcasts',
      params: {
        limit: limit?.toString?.(),
        ...rest,
      },
      signal,
    };

    const { data: { broadcasts }, flash } =
      await this.ajax<{ broadcasts: any[] }>(request);
    const response: EndpointResponse = { data: broadcasts };

    return response;
  }

  async events(
    { subdomain, ticket, after, limit, signal }: EndpointRequestParams&{ ticket: string },
  ): Promise<EndpointResponse> {
    const request = {
      route: `/v1/tickets/${ticket}/events`,
      params: {
        subdomain,
        limit: limit?.toString?.(),
        after,
      },
      signal,
    };

    const { data: { events }, flash } = await this.ajax<{ events: any[] }>(request);
    const response: EndpointResponse = { data: events, flash };
    if (this.config.enrichResponses && (!limit || events.length >= limit)) {
      response.lastKey = events[events.length - 1]?.sent_at?.toString?.();
    }

    return response;
  }

  async bulk(bulkAction: BulkAction, subdomain?: string) {
    const request = {
      method: <const>'PUT',
      route: '/v1/bulk',
      payload: Object.assign(toCase(bulkAction, snakeCase), { subdomain }),
    };

    const { data } = await this.ajax<{ bulk_job_id: string }>(request);
    return toCase(data, camelCase) as { bulkJobId: string };
  }
}
